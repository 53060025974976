import React from 'react'
import Helmet from 'react-helmet'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCartArrowDown, faBullhorn, faCashRegister, faClipboardList } from '@fortawesome/free-solid-svg-icons'
import './index.css'
import '../all.scss'
import styles from './index.module.scss'

// Components
import Header from '../Header'
import Footer from '../Footer'

library.add(faCartArrowDown, faBullhorn, faCashRegister, faClipboardList)

const TemplateWrapper = ({ children, dark }) => (
  <div>
    <div className={styles.wrapper}>
      <Helmet
        title="Cart Saver for Shopify"
        meta={[
          { name: 'description', content: 'Allow customers to save their carts for future purchases in your Shopify store.' },
        ]}
      />
      <Header dark={dark} />
      {children}
      <Footer />
    </div>
  </div>
)

export default TemplateWrapper
