import React from 'react'
import classNames from 'classnames'
import Link from 'gatsby-link'
import styles from './index.module.scss'

// Components
import Container from '../Layout/Container'

const Footer = () => (
  <div className={styles.footer}>
    <Container>
      <div className={styles.footerLinks}>
        <small className={styles.copyright}>Made by <a target="_blank" rel="noopener noreferrer" href="https://activatedapps.com">Activated Apps</a></small>
        <ul className={classNames(styles.site, styles.horizontalLinks)}>
          <li className={styles.horizontalLinkItem}>
            <Link className={styles.link} to="/terms">Terms of Use</Link>
          </li>
          <li className={styles.horizontalLinkItem}>
            <Link className={styles.link} to="/privacy-policy">Privacy Policy</Link>
          </li>
          <li className={styles.horizontalLinkItem}>
            <Link className={styles.link} to="/dpa">Data Processing Agreement</Link>
          </li>
          <li className={styles.horizontalLinkItem}>
            <Link className={styles.link} to="/updates">Updates</Link>
          </li>
          <li className={styles.horizontalLinkItem}>
            <Link className={styles.link} to="/support">Support</Link>
          </li>
          <li className={styles.horizontalLinkItem}>
            <Link className={styles.link} to="/contact">Contact</Link>
          </li>
        </ul>
      </div>
    </Container>
  </div>
)

export default Footer