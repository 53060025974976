import React, { Component } from 'react'
import Link from 'gatsby-link'
import classNames from 'classnames'
import styles from './index.module.scss'
import Logo from './Logo'
import Container from '../Layout/Container'

class Header extends Component {
  constructor(props) {
    super(props)

    this.state = {
      menuOpen: false,
    }

    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  handleOpen() {
    this.setState({
      menuOpen: true
    })
  }

  handleClose() {
    this.setState({
      menuOpen: false
    })
  }

  render() {
    const { dark } = this.props
    return (
      <Container>
        <div 
          className={classNames(
            styles.headerWrapper, 
            {[`${styles.dark}`]: dark},
            {[`${styles.light}`]: !dark},
            {[`${styles.noMargin}`]: dark}
          )}
        >
          <div className={styles.header}>
            <h1 className={classNames(styles.logo, {[`${styles.light}`]: !dark})}>
              <Link
                to="/"
                className={styles.logoLink}
              >
                <Logo className={styles.logoIcon} />
              </Link>
            </h1>
            <div onClick={this.handleOpen} className={styles.hamburgerMenu}>
              <span className={classNames(styles.bar, {[`${styles.darkBar}`]: dark})}></span>
              <span className={classNames(styles.bar, {[`${styles.darkBar}`]: dark})}></span>
              <span className={classNames(styles.bar, {[`${styles.darkBar}`]: dark})}></span>
            </div>
            <div className={classNames(styles.navWrapper, {[`${styles.show}`]: this.state.menuOpen}, {[`${styles.hide}`]: !this.state.menuOpen})}>
              <nav className={styles.siteNav}>
                <div onClick={this.handleClose} className={styles.close}>
                  <span className={styles.crossLeft}></span>
                  <span className={styles.crossRight}></span>
                </div>
                <Link
                  className={classNames(styles.navLink, {[`${styles.dark}`]: dark})}
                  to="/blog"
                >
                  Blog
                </Link>
                <Link
                  className={classNames(styles.navLink, {[`${styles.dark}`]: dark})}
                  to="/support"
                >
                  Support
                </Link>
                <Link
                  className={classNames(styles.navLink, {[`${styles.dark}`]: dark})}
                  to="/contact"
                >
                  Contact
                </Link>
              </nav>
            </div>
          </div>
        </div>
      </Container>
    )
  }
}

export default Header
