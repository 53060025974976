import React from 'react'
import Container from './Container'
import styles from './Section.module.scss'

const Section = ({ children, title }) => (
  <section className={styles.section}>
    <Container>
      {title &&
        <header className={styles.sectionTitle}>
          <h2 className={styles.title}>{title}</h2>
        </header>
      }
      {children}
    </Container>
  </section>
)

export default Section
